import React from 'react';
import unityIcon from './react-icon.png';
import arkuniAvatar from './arkuni-avatar.png';
import { App } from "shared/lib/components";

const primaryBackgroundColor = "#20232a";
const secondaryBackgroundColor = "#282c34";
const reactBlue = "#14b0da";
const reactDarkBlue = "#086b86";

export const ReactApp: React.FC = () =>
{
	return (
		<App
			title={"React SOS"}
			topic={"React"}
			topicIconUrl={unityIcon}
			arkuniAvatarUrl={arkuniAvatar}
			email={"react@arkuni.com"}
			highlights={[
				'Getting started with React, NodeJS, JavaScript/TypeScript and HTML/CSS',
				'Ways to do internationalization, state management, and code sharing', // testing, analytics
				'Working with popular packages like React-Router, Material-UI, Lerna, Axios, and React-i18next', // , Jest, Enzyme, Apollo/GraphQL
				'Help with a general or specific problems',
				'A shoulder to cry on while sharing your developer frustrations'
			]}
			primaryColor={{
				main: reactBlue,
				dark: reactDarkBlue,
				light: reactBlue,
				contrastText: "#FFF"
			}}
			primaryBackgroundColor={primaryBackgroundColor}
			secondaryBackgroundColor={secondaryBackgroundColor}
		/>
	);
}
